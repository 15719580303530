@import "variables";
@import "mixins/breakpoints";

.game-scroller-li {
  width: auto;
  height: fit-content;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.8);
  margin-left: 0.25em;
  margin-right: 0.25em;
  margin-top: 1.4em;
  border-radius: 4px;
  @include media-breakpoint-up(md) {
    margin-left: 0.5em;
    margin-right: 0.5em;
  }
}

.game-scroller-img {
  border-radius: 4px 4px 0 0;
  display: block;
  width: 115px;
  &:only-child {
    border-radius: 4px;
  }
  @include media-breakpoint-down(sm) {
    width: 80px;
  }
  @include media-breakpoint-down(md) {
    width: 100px;
  }
}

.game-scroller-img__with-engine {
  width: 120px;
  @include media-breakpoint-down(sm) {
    width: 85px;
  }
  @include media-breakpoint-down(md) {
    width: 105px;
  }
}

.i__has_engine_support {
  background-color: #151a1e;
  display: flex;
  height: 1.5em;
  justify-content: center;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 0 0 4px 4px;

  @include media-breakpoint-up(md) {
    height: 2em;
  }

  svg {
    align-self: center;
  }

  span {
    font-size: 0.9rem;
    align-self: center;
    margin-left: 0.5em;
    @include media-breakpoint-up(sm) {
      font-size: 1rem;
    }
    @include media-breakpoint-up(md) {
      font-size: 1.2rem;
    }
    @include media-breakpoint-up(lg) {
      font-size: 1.4rem;
    }
  }
}

.li__has_engine_support {
  margin-top: 0;
}

.icon-color--black {
  color: $black;
}
.icon-color--white {
  color: $white;
}
.icon-color--orange {
  color: $orange-500;
}
