// Implementation

@import "game";
@import "mixins/breakpoints";


.js-game-scroller-wrap {
  margin-top: 20px;
  height: auto;
  overflow-x: hidden;
  padding-bottom: 20px;
  position: relative;

  .shadowLeft {
    width: 50px;
    height: 100%;
    left:0;
    position: absolute;
      &:before {
        content: '';
        position: absolute;
        height: 100%;
        width: 100%;
        left: 0;
        bottom: 0;
        opacity: 1;
        z-index: 1;
        background: linear-gradient(to right, rgba($moments-background-color, 1), rgba($moments-background-color, 0));
      }

      @include media-breakpoint-up(sm) {
        width: 70px;
      }
      @include media-breakpoint-up(md) {
        width: 90px;
      }
      @include media-breakpoint-up(lg) {
        width: 150px;
      }
      @include media-breakpoint-up(xl) {
        width: 200px;
      }
  }

  .shadowRight {
    width: 50px;
    height: 100%;
    right:0;
    position: absolute;
    &:before {
      content: '';
      position: absolute;
      height: 100%;
      width: 100%;
      left: 0;
      bottom: 0;
      opacity: 1;
      z-index: 1;
      background: linear-gradient(to left, rgba($moments-background-color, 1), rgba($moments-background-color, 0));

    }
    @include media-breakpoint-up(sm) {
      width: 70px;
    }
    @include media-breakpoint-up(md) {
      width: 90px;
    }
    @include media-breakpoint-up(lg) {
      width: 150px;
    }
    @include media-breakpoint-up(xl) {
      width: 200px;
    }
  }

  .game-scroller-section {
    width: 100%;
  }

  .game-scroller-mainDiv {
    display: flex;
    animation: bannermove 50s linear infinite;
    width: fit-content;
    &:hover {
      -webkit-animation-play-state: paused;
      animation-play-state: paused;
    }
    &.is-paused {
      -webkit-animation-play-state: paused;
      animation-play-state: paused;
    }
  }

  .game-scroller-div {
    width: auto;
  }

  .game-scroller-ul {
    height: auto;
    display: flex;
    list-style-type: none;
    padding-left: 0;
    margin: 0;
  }

  @keyframes bannermove {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-50%);
    }
  }
}


